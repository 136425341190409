<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
</style>
<template lang="pug">
div.container-property
  .container
    .row.justify-content-center.align-items-center.async(style='height: 100vh' :class='{done:done}')
      .col-xl-5.col-lg-6.col-md-7
        h3.title.py-3 원하는 workspace 접속하기
        .mb-4
          router-link.btn.btn-default.btn-block.text-left(v-for='(e, $index) in property' :key='e.id' :to='`/property/${e.id}`')
            span {{e.name || '(이름없음)'}}
            small.float-right.text-muted ID: {{$index+1}}
          br
          router-link(to='/property/new').btn.btn-light.text-primary.bg-light-primary 새로운 workspace 추가하기
          router-link(to='/logout').btn.btn-default.text-secondary.float-right 계정 로그아웃

</template>

<script>

export default {
  name: 'index',
  components: {

  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
  },
  mounted() {
    this.load()
  },
  data() {
    return {
      done: false,
      property: [],
    }
  },
  methods: {
    async load() {
      try {
        const r = await this.$http.get('/v1/property')
        this.property = r.data.property
        // if (this.property.length == 1) {
        //   this.$router.push({
        //     path: `/property/${this.property[0].id}`,
        //   })
        // }
        this.done = true
      } catch (error) {
        console.log({error})
      }
    },

  },
}
</script>
